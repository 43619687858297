import React from 'react'
import styled from 'styled-components'

import { rspTheme } from '../../../styles/rspTheme'

interface GridContainerProps {
  children: React.ReactNode
}

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;
  max-width: 1140px;
  margin: 0 auto;

  @media (max-width: ${rspTheme.breakpoints.values.sm}px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
  }
`

const GridContainer = (props: GridContainerProps) => {
  const { children } = props

  return <GridWrapper>{children}</GridWrapper>
}

export default GridContainer
