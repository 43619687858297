import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import CarouselHero from '../components/CarouselHero'
import EventCardGrid from '../components/EventCardGrid'
import Layout from '../components/Layout'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'

const EventsPage = (props: PageProps<Queries.eventsPageQuery>) => {
  const pageData = props?.data?.contentstackPageEvents
  const cardData = props?.data?.allContentstackTopicEvent?.nodes
  const breadcrumbLinks = [{ text: pageData?.title || '' }]

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={props?.pageContext?.slug}
      title={metaTitle}
    >
      <CarouselHero
        events={pageData?.page_header?.[0]?.events}
        headline={pageData?.page_header?.[0]?.headline}
        locale={pageData?.locale}
        type={pageData?.page_header?.[0]?.type}
      />
      <EventCardGrid cards={cardData} locale={pageData?.locale} />
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.eventsPageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageEvents?.schema_markup} />
    <SchemaMarkupBreadcrumb pageTitle={data?.contentstackPageEvents?.title} />
  </>
)

export default EventsPage

export const data = graphql`
  query eventsPage($pageId: String, $locale: String) {
    contentstackPageEvents(
      id: { eq: $pageId }
      publish_details: { locale: { eq: $locale } }
    ) {
      id
      url
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        headline
        type
        events {
          id
          publish_details {
            locale
          }
          headline
          short_description
          event_start
          event_end
          event_page {
            ...NavigationLinkFragment
          }
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 1140, imgixParams: { q: 65 })
            }
          }
        }
      }
    }

    allContentstackTopicEvent(
      sort: { fields: event_start, order: ASC }
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        id
        headline
        short_description
        event_start
        event_page {
          ...NavigationLinkFragment
        }
        feature_image {
          description
          imgixImage {
            gatsbyImageData(width: 320, imgixParams: { q: 60 })
          }
        }
      }
    }
  }
`
