import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { CarouselProvider, Dot, Slide } from 'pure-react-carousel'
import React from 'react'

// import "pure-react-carousel/dist/react-carousel.es.css";
import Link from '../Link'
import GridContainer from '../UI/GridContainer'
import LinkCTA from '../UI/LinkCTA'
import Wrapper from '../UI/Wrapper'
import {
  Back,
  ButtonContainer,
  CarouselHeroSection,
  DotContainer,
  Next,
  SlideWrapper,
  StyledSlider,
} from './styles'

interface CarouselHeroProps {
  headline: string
  type: 'Resources' | 'Events'
  maxSlides?: number
  locale: string
  resources?: any[]
  events?: any[]
}

const SlideContent = (props: { slide: any; slideType: string }) => {
  const { slide, slideType } = props

  const image =
    slideType === 'Resources'
      ? getImage(slide?.resource?.[0]?.feature_image?.imgixImage)
      : getImage(slide?.feature_image?.imgixImage)
  const alt =
    slideType === 'Resources'
      ? slide?.resource?.[0]?.feature_image?.description
      : slide?.feature_image?.description
  const slideSubhead =
    slideType === 'Resources' ? slide?.resource?.[0]?.headline : slide?.headline
  const slideBody =
    slideType === 'Resources'
      ? slide?.resource?.[0]?.short_description
      : slide?.short_description

  const formattedStartDate = slide?.event_start
    ? new Date(slide.event_start).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : ''

  const formattedEndDate = slide?.event_end
    ? new Date(slide.event_end).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : ''

  const formattedStartTime =
    formattedEndDate !== formattedStartDate
      ? ''
      : new Date(slide.event_start).toLocaleTimeString('en-US', {
          timeZoneName: 'short',
          hour: 'numeric',
          minute: '2-digit',
        })

  const dateTimeString =
    formattedStartTime !== ''
      ? `${formattedStartDate}, ${formattedStartTime}`
      : `${formattedStartDate} - ${formattedEndDate}`

  return (
    <SlideWrapper>
      <div className="imageContainer">
        {image ? <GatsbyImage alt={alt || ''} image={image} /> : null}
        {slide?.event_page?.[0] ? (
          <div className="ctaContainer">
            <LinkCTA arrow link={slide?.event_page?.[0]} variant="primary">
              {slide?.event_page?.[0]?.text}
            </LinkCTA>
          </div>
        ) : null}
      </div>
      <GridContainer>
        <Typography className="slideSubhead" component="h2" variant="h3">
          <Link
            directUrl={slideType === 'Resources' ? slide?.url : null}
            link={slideType === 'Events' ? slide?.event_page?.[0] : null}
          >
            {slideSubhead}
          </Link>
        </Typography>
        <Typography className="slideBody" component="p" variant="body1">
          {slideBody}
        </Typography>
        {formattedStartDate ? (
          <Typography className="slideBody" component="p" variant="body1">
            {dateTimeString}
          </Typography>
        ) : null}
      </GridContainer>
    </SlideWrapper>
  )
}

const CarouselHero = (props: CarouselHeroProps) => {
  const { headline, type, resources = [], events = [] } = props
  const isMobile = useMediaQuery('(max-width:680px)')

  const slideData = type === 'Resources' ? resources : events

  return (
    <CarouselHeroSection>
      <Wrapper>
        <Typography className="heroHeadline" component="h1" variant="h1">
          {headline}
        </Typography>

        <CarouselProvider
          naturalSlideHeight={690}
          naturalSlideWidth={isMobile ? 440 : 1140}
          step={1}
          totalSlides={slideData.length}
          visibleSlides={1}
        >
          <StyledSlider role={undefined}>
            {slideData?.map((slide: any, i: number) => (
              <Slide
                aria-selected={undefined}
                index={i}
                key={slide.id}
                role={undefined}
              >
                <SlideContent slide={slide} slideType={type} />
              </Slide>
            ))}
          </StyledSlider>
          <ButtonContainer>
            <Back>
              <>
                <NavigateBeforeIcon />
                <span className="sr-only">Carousel backward</span>
              </>
            </Back>
            <DotContainer>
              {slideData?.map((slide: any, i: number) => (
                <Dot key={`dot${i}`} slide={i} />
              ))}
            </DotContainer>
            <Next>
              <>
                <NavigateNextIcon />
                <span className="sr-only">Carousel forward</span>
              </>
            </Next>
          </ButtonContainer>
        </CarouselProvider>
      </Wrapper>
    </CarouselHeroSection>
  )
}

export default CarouselHero
