import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const CardGridSection = styled.section`
  padding: 66px 0 72px;

  .cardGrid {
    justify-content: center;
    gap: 40px;

    > * {
      width: 30%;
      min-width: 320px;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0;
  }
`
