import { ButtonBack, ButtonNext, Slider } from 'pure-react-carousel'
import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const CarouselHeroSection = styled.section`
  padding: 72px 0 48px;

  .heroHeadline {
    color: ${rspTheme.palette.primary.main};
    margin-bottom: 48px;
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0 40px;

    .heroHeadline {
      font-size: 2.8rem;
      margin-bottom: 32px;
    }
  }
`

const buttonStyle = `
width: 40px;
height: 40px;
background-color: transparent;
border: 1px solid ${rspTheme.palette.primary.main};
border-radius: 50%;
color: ${rspTheme.palette.primary.main};
padding: 0;
margin: 0;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
transition: all 0.3s ease-in-out;

&:hover:not(:disabled),
&:focus:not(:disabled) {
  color: ${rspTheme.palette.primary.background};
  background-color: ${rspTheme.palette.primary.main};
}

&:disabled {
  opacity: 0.4;
  cursor: unset;
}
`

export const Back = styled(ButtonBack)`
  ${buttonStyle}
`
export const Next = styled(ButtonNext)`
  ${buttonStyle}
`

export const DotContainer = styled.div`
  margin: 0 24px;
  max-width: 360px;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 4px;
  position: relative;

  > * {
    flex-shrink: 0;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    border-bottom: 2px solid ${rspTheme.palette.primary.dark};
    bottom: calc(50% - 1px);
    left: 18px;
    right: 18px;
    z-index: -1;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      display: none;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px auto 0;

  > * {
    flex-shrink: 0;
  }

  .carousel__dot {
    border: none;
    width: 18px;
    height: 18px;
    background-color: ${rspTheme.palette.primary.light};
    border-radius: 50%;
    margin: 0 7px;
    padding: 0;
    transition: all 0.2s ease-in-out;

    &.carousel__dot--selected {
      display: inline-block;
      background-color: ${rspTheme.palette.primary.dark};
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    margin-bottom: 36px;

    .carousel__dot {
      border: none;
      width: 8px;
      height: 8px;
      background-color: ${rspTheme.palette.primary.main};
      border-radius: 50%;
      margin: 0 12px;
      padding: 0;
      transform: scale(1);
      transform-origin: center;
      transition: all 0.2s ease-in-out;

      &.carousel__dot--selected {
        display: inline-block;
        transform: scale(2);
        background-color: ${rspTheme.palette.secondary.main};
      }
    }
  }
`

export const StyledSlider = styled(Slider)`
  .carousel__inner-slide {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      height: 100%;
    }
  }
`

export const SlideWrapper = styled.div`
  .imageContainer {
    margin-bottom: 24px;
    height: 70%;
    position: relative;

    .gatsby-image-wrapper {
      height: 100%;
      max-height: 480px;
      border-radius: 6px;
    }

    .ctaContainer {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);

      a {
        white-space: nowrap;
      }
    }
  }

  .slideSubhead {
    grid-column: 1 / 6;
    color: ${rspTheme.palette.primary.main};

    a {
      color: inherit;
      text-decoration-color: transparent;
      transition: text-decoration-color 0.2s ease-in-out;

      &:hover,
      &:focus {
        text-decoration-color: inherit;
      }
    }
  }

  .slideBody {
    grid-column: 6 / 11;
    margin-bottom: 8px;
  }

  @media (max-width: 1000px) {
    .slideBody {
      grid-column: 6 / -1;
    }
  }

  @media (max-width: 900px) {
    .imageContainer {
      height: 50%;
    }

    .slideSubhead,
    .slideBody {
      grid-column: 1 / -1;
      margin-bottom: 16px;
    }
  }
`
