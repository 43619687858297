import Grid from '@material-ui/core/Grid'
import React from 'react'

import FeatureCardVertical from '../UI/FeatureCardVertical'
import Wrapper from '../UI/Wrapper'
import { CardGridSection } from './styles'

interface EventCardGridProps {
  cards: any[]
  locale: string
}

const EventCardGrid = (props: EventCardGridProps) => {
  const { cards, locale } = props

  return (
    <CardGridSection>
      <Wrapper>
        <Grid className="cardGrid" container>
          {cards?.map((card: any) => (
            <FeatureCardVertical
              body={card?.short_description}
              cardType=""
              featureImage={card?.feature_image}
              headline={`${card?.headline} ${
                card?.event_start
                  ? `- ${new Date(card.event_start).toLocaleDateString(locale, {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}`
                  : ''
              }`}
              key={card?.id}
              linkTarget={
                card?.event_page?.[0]?.external_url ||
                card?.event_page?.[0]?.internal_page?.[0]?.url
              }
            />
          ))}
        </Grid>
      </Wrapper>
    </CardGridSection>
  )
}

export default EventCardGrid
